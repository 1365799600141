$header-button__size                : 48px !default;
$header-button__margin              : 0 0 0 $spacer !default;
$header-button__margin--extra-large : 0 0 0 $spacer--medium !default;
$header-button__padding             : 0 !default;

$header-button__icon-margin         : 0 0 2px 0 !default;
$header-button__icon-margin--medium : 0 0 4px 0 !default;
$header-button__icon-padding        : 0 0 4px 0 !default;
$header-button__icon-color          : $gray-darker !default;
$header-button__icon-color--hover   : $color-primary !default;
$header-button__transition          : $transition-base !default;

$header-button__text-font-family    : $font-family-base !default;
$header-button__text-color          : $gray !default;
$header-button__text-font-size      : 10px !default;
$header-button__text-line-height    : 10px !default;

$header-button__counter-top         : 0 !default;
$header-button__counter-top--medium : -2px !default;
$header-button__counter-right       : 8px !default;
$header-button__counter-font-size   : $font-size-extra-small !default;
$header-button__counter-background  : $color-primary !default;
$header-button__counter-color       : $white !default;
$header-button__counter-size        : 14px !default;
$header-button__counter-border      : 2px solid $white !default;

$header-button__counter-dot-size    : 2px !default;
$header-button__counter-dot-bg-color: $white !default;

.header-button {
    position: relative;
    height: $header-button__size;
    min-width: $header-button__size;
    display: flex;
    margin: $header-button__margin;
    padding: $header-button__padding;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: none;
    cursor: pointer;
    text-decoration: none;
    background-color: #fff;
    font-family: $header-button__text-font-family;
    @include mq($screen-xl) {
        margin: $header-button__margin--extra-large;
    }

    &.focus-visible,
    &:hover {
        text-decoration: none;
        .header-button__icon {
            fill: $header-button__icon-color--hover;
        }
    }

    &--reset-margin {
        margin: 0;
    }

    &__icon {
        margin: $header-button__icon-margin;
        padding: $header-button__icon-padding;
        fill: $header-button__icon-color;
        transition: $header-button__transition;
        @include mq($screen-m) {
            margin: $header-button__icon-margin--medium;
        }
    }

    &__text {
        font-size: $header-button__text-font-size;
        line-height: $header-button__text-line-height;
        text-align: center;
        color: $header-button__text-color;
    }

    &__counter {
        position: absolute;
        top: $header-button__counter-top;
        right: $header-button__counter-right;
        display: flex;
        width: $header-button__counter-size;
        height: $header-button__counter-size;
        border: $header-button__counter-border;
        border-radius: $header-button__counter-size;
        line-height: $header-button__counter-size;
        font-size: $header-button__counter-font-size;
        text-align: center;
        color: $header-button__counter-color;
        background-color: $header-button__counter-background;
        &--hidden {
            display: none;
        }
        @include mq($screen-m) {
            top: $header-button__counter-top--medium;
        }
        &-dot {
            width: $header-button__counter-dot-size;
            height: $header-button__counter-dot-size;
            border-radius: $header-button__counter-dot-size;
            margin: auto;
            background-color: $header-button__counter-dot-bg-color;
        }
    }
}
