$dashboard-nav-padding: 15px !default;
$dashboard-nav-margin : 15px !default;

.dashboard-nav {
    margin-bottom: $dashboard-nav-margin;

    &__mobile {
        display: block;
        position: relative;
        border-width: 1px 0;
        border-color: $gray-dark;
        border-style: solid;
        padding: $dashboard-nav-padding 0;
        cursor: pointer;

        @include mq($screen-m) {
            display: none;
        }

        &:after {
            content: '\25bc';
            position: absolute;
            width: 15px;
            height: 15px;
            right: 15px;
            top: 0;
            bottom: 0;
            margin: auto;
        }

        &--active {
            &:after {
                content: '\25b2';
            }
        }
    }

    &__content {
        display: none;
        background-color: $gray-lighter;
        padding: $dashboard-nav-padding;
        margin-bottom: $dashboard-nav-margin;

        &--visible {
            display: block;
        }

        @include mq($screen-m) {
            display: block;
        }
    }

    &__list {
        margin: 0;
    }

    &__item {
        padding: 0;
        transition: $transition-base;

        &:hover {
            background-color: $white;
        }
    }

    &__title {
        margin-bottom: $dashboard-nav-margin;
        padding-bottom: 10px;
        border-bottom: 1px solid $gray;
    }

    &__item > a,
    &__link {
        display: block;
        padding: 15px;
    }

    // This class is changing by Magento
    .current {
        position: relative;
        padding: $dashboard-nav-padding $dashboard-nav-padding $dashboard-nav-padding ($dashboard-nav-padding * 2);
        margin-left: -$dashboard-nav-margin;
        border-left: 2px solid $red;
        color: $red;

        &:hover {
            background-color: initial;
        }

        & > a {
            padding: 0;
        }
    }

    // This clases to disabled links module
    .nav.item {
        @extend .list__item;
        @extend .dashboard-nav__item;
    }

    .nav.item.current {
        @extend .current;
    }

    .nav.item.current {
        & > a {
            color: $red;
        }
    }

    .nav.item > a {
        @extend .link--invert;
    }
}
