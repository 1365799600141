// Input
$quantity-update__input-border                       : 1px solid $gray-dark !default;
$quantity-update__input-width                        : 48px !default;
$quantity-update__input-color                        : $gray-dark !default;

// Button
$quantity-update__button-background                  : $white !default;
$quantity-update__button-border                      : 1px solid $gray-dark !default;
$quantity-update__button-background-hover--disabled  : $white !default;
$quantity-update__button-fill-hover--disabled        : $gray !default;

// Icon
$quantity-updater__icon-fill                         : $gray-dark !default;
$quantity-updater__icon-spacing                      : 12px !default;

// Error
$quantity-updater__error-margin                      : 0 0 0 $spacer !default;
$quantity-updater__error-max-width                   : 120px !default;

.quantity-update {
    display: flex;

    &__input {
        border-top: $quantity-update__input-border;
        border-bottom: $quantity-update__input-border;
        border-left: 0;
        border-right: 0;
        border-radius: 0;
        width: $quantity-update__input-width;
        height: $quantity-update__input-width;
        color: $quantity-update__input-color;
        text-align: center;
        appearance: none;

        &.focus-visible {
            @include focus-input();
        }
    }

    &__button {
        border: $quantity-update__button-border;
        background: $quantity-update__button-background;
        margin: 0;

        &.focus-visible {
            @include focus-input();
        }

        &--plus {
            border-radius: 0 100% 100% 0;
            border-left: 0;
        }

        &--minus {
            border-radius: 100% 0 0 100%;
            border-right: 0;
        }

        &--disabled {
            pointer-events: none;

            &:hover,
            &.focus-visible {
                background: $quantity-update__button-background-hover--disabled;

                .quantity-update__icon {
                    fill: $quantity-update__button-fill-hover--disabled;
                }
            }

            .quantity-update__icon {
                fill: $quantity-update__button-fill-hover--disabled;
            }
        }
    }

    &__icon {
        fill: $quantity-updater__icon-fill;
        width: $quantity-updater__icon-spacing;
        height: $quantity-updater__icon-spacing;
    }

    div.mage-error {
        order: 1;
        margin: $quantity-updater__error-margin;
        max-width: $quantity-updater__error-max-width;
    }

    // Disable spin buttons in number input type
    input[type="number"]::-webkit-outer-spin-button, // sass-lint:disable-line no-vendor-prefixes
    input[type="number"]::-webkit-inner-spin-button { // sass-lint:disable-line no-vendor-prefixes
        -webkit-appearance: none; // sass-lint:disable-line no-vendor-prefixes
        margin: 0;
    }

    input[type="number"] { // sass-lint:disable-line no-vendor-prefixes
        -moz-appearance: textfield; // sass-lint:disable-line no-vendor-prefixes
    }
}
