$catalog-grid-item__base-spacing                  : $spacer !default;
$catalog-grid-item__padding                       : 0 !default;
$catalog-grid-item__border                        : 1px solid $gray-lightest !default;
$catalog-grid-item__font-size                     : $font-size-base !default;
$catalog-grid-item__font-size\@large              : 16px !default;
$catalog-grid-item__line-height                   : 1.5 !default;
$catalog-grid-item__font-weight                   : $font-weight-bold !default;

$catalog-grid-item__badge-top-position            : $spacer--medium !default;
$catalog-grid-item__badge-left-position           : 0 !default;
$catalog-grid-item__badge-z-index                 : 1 !default;
$catalog-grid-item__badge-padding                 : 5px 12px !default;
$catalog-grid-item__badge-font-size               : $font-size-small !default;
$catalog-grid-item__badge-border-radius           : 0 !default;

$catalog-grid-item__link-width                    : 100% !default;
$catalog-grid-item__link-z-index--focus           : 0 !default;
$catalog-grid-item__link-outline--focus           : none !default;

$catalog-grid-item__image-margin                  : 0 auto !default;
$catalog-grid-item__image-width                   : auto !default;
$catalog-grid-item__image-width--lazyload         : 100% !default;
$catalog-grid-item__image-height--lazyload        : 300px !default;
$catalog-grid-item__image-height                  : auto !default;

$catalog-grid-item__loader-margin-top             : 30% !default;

$catalog-grid-item__link-name-overflow            : hidden !default;
$catalog-grid-item__link-name-overflow--visible   : visible !default;
$catalog-grid-item__link-name-white-space         : nowrap !default;
$catalog-grid-item__link-name-white-space--visible: normal !default;
$catalog-grid-item__link-name-text-overflow       : ellipsis !default;

$catalog-grid-item__content-bg-color              : $white !default;
$catalog-grid-item__content-padding               : $spacer !default;
$catalog-grid-item__content-width                 : 100% !default;
$catalog-grid-item__content-top\@large            : 100% !default;
$catalog-grid-item__content-height                : 67px !default;
$catalog-grid-item__content-height\@large         : 73px !default;
$catalog-grid-item__content-translateY            : translateY(-$catalog-grid-item__content-height) !default;
$catalog-grid-item__content-translateY\@large     : translateY(-$catalog-grid-item__content-height\@large) !default;
$catalog-grid-item__content-translateY--active    : translateY(-100%) !default;

$catalog-grid-item__name-color                    : $font-color-base !default;
$catalog-grid-item__name-font-weight              : $font-weight-normal !default;
$catalog-grid-item__name-margin-botttom           : $spacer !default;
$catalog-grid-item__name-padding--bigger          : $spacer--medium 0 !default;

$catalog-grid-item__rating-margin-bottom          : $spacer !default;
$catalog-grid-item__price-margin-bottom           : $spacer !default;
$catalog-grid-item__price-label-font-weight       : $font-weight-normal !default;

$catalog-grid-item__primary-padding               : 0 $spacer !default;
$catalog-gird-item__primary-form-margin           : 0 !default;
$catalog-grid-item__primary-icon-fill             : $gray-darker !default;

$catalog-grid-item__action-size                   : 48px !default;
$catalog-grid-item__action-padding                : 0 !default;
$catalog-grid-item__action-background             : transparent !default;
$catalog-grid-item__action-border                 : none !default;
$catalog-grid-item__action-cursor                 : pointer !default;
$catalog-grid-item__action-icon-size              : 18px !default;
$catalog-grid-item__action-icon-transition        : $transition-base !default;
$catalog-grid-item__action-icon-fill              : $gray-darker !default;
$catalog-grid-item__action-icon-fill-hover        : $green !default;

$catalog-grid-item__IE-margin                     : 0 $spacer $spacer--medium $spacer !default;
$catalog-grid-item__IE-width                      : calc(50% - #{$spacer--medium} - 1px) !default;
$catalog-grid-item__IE-width\@medium              : calc(100% / 3 - #{$spacer--medium} - 1px) !default;

.catalog-grid-item {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border: $catalog-grid-item__border;
    padding: $catalog-grid-item__padding;
    overflow: hidden;

    @include isIE() {
        margin: $catalog-grid-item__IE-margin;
        width: $catalog-grid-item__IE-width;

        @include mq($screen-m) {
            width: $catalog-grid-item__IE-width\@medium;
        }
    }

    @include mq($screen-m) {
        flex-direction: row;

        &:hover,
        &.focus-within {
            .catalog-grid-item__details {
                transform: $catalog-grid-item__content-translateY--active;
            }

            .catalog-grid-item__name-link {
                overflow: $catalog-grid-item__link-name-overflow--visible;
                white-space: $catalog-grid-item__link-name-white-space--visible;
            }
        }

        @include isIE() {
            &:hover {
                .catalog-grid-item__details {
                    transform: $catalog-grid-item__content-translateY--active;
                }

                .catalog-grid-item__name-link {
                    overflow: $catalog-grid-item__link-name-overflow--visible;
                    white-space: $catalog-grid-item__link-name-white-space--visible;
                }
            }
        }
    }

    &__badge {
        position: absolute;
        top: $catalog-grid-item__badge-top-position;
        left: $catalog-grid-item__badge-left-position;
        z-index: $catalog-grid-item__badge-z-index;
        border-radius: $catalog-grid-item__badge-border-radius;
        padding: $catalog-grid-item__badge-padding;
        font-size: $catalog-grid-item__badge-font-size;
    }

    &__link {
        display: flex;
        justify-content: center;
        flex: 1 1 auto;
        align-items: center;
        align-self: stretch;
        width: $catalog-grid-item__link-width;

        &.focus-visible {
            z-index: $catalog-grid-item__link-z-index--focus;
            outline: $catalog-grid-item__link-outline--focus;
        }
    }

    &__image-wrapper {
        @include mq($screen-m) {
            &:after {
                content: "";
                display: block;
                height: $catalog-grid-item__content-height;
                @include mq($screen-xxl) {
                    height: $catalog-grid-item__content-height\@large;
                }
            }
        }

        .image {
            margin: $catalog-grid-item__image-margin;
            width: $catalog-grid-item__image-width;
            height: $catalog-grid-item__image-height;
        }

        .lazyload,
        .lazyloading {
            width: $catalog-grid-item__image-width--lazyload;
            height: $catalog-grid-item__image-height--lazyload;
        }

        .loader__circle {
            margin-top: $catalog-grid-item__loader-margin-top;
        }
    }

    &__name {
        font-weight: $catalog-grid-item__name-font-weight;
        font-size: $catalog-grid-item__font-size;
        line-height: $catalog-grid-item__line-height;
        margin-bottom: $catalog-grid-item__name-margin-botttom;

        @include mq($screen-xxl) {
            font-size: $catalog-grid-item__font-size\@large;
        }

        &--bigger {
            padding: $catalog-grid-item__name-padding--bigger;
        }
    }

    &__name-link {
        display: block;
        color: $catalog-grid-item__name-color;

        @include mq($screen-m) {
            overflow: $catalog-grid-item__link-name-overflow;
            white-space: $catalog-grid-item__link-name-white-space;
            text-overflow: $catalog-grid-item__link-name-text-overflow;
        }
    }

    &__rating {
        margin-bottom: $catalog-grid-item__rating-margin-bottom;
    }

    &__price {
        margin-bottom: $catalog-grid-item__price-margin-bottom;
        padding: $catalog-grid-item__padding;
        font-size: $catalog-grid-item__font-size;
        line-height: $catalog-grid-item__line-height;
        font-weight: $catalog-grid-item__font-weight;
        white-space: nowrap;

        @include mq($screen-xxl) {
            font-size: $catalog-grid-item__font-size\@large;
        }

        .price-label {
            font-weight: $catalog-grid-item__price-label-font-weight;
        }
    }

    &__details {
        padding: $catalog-grid-item__content-padding;
        background: $catalog-grid-item__content-bg-color;
        transition: $transition-base;

        @include mq($screen-m) {
            position: absolute;
            top: $catalog-grid-item__content-top\@large;
            transform: $catalog-grid-item__content-translateY;
            width: $catalog-grid-item__content-width;
            border-top: $catalog-grid-item__border;
        }

        @include mq($screen-xxl) {
            transform: $catalog-grid-item__content-translateY\@large;
        }
    }

    &__options {
        .swatch {
            margin: $catalog-grid-item__base-spacing 0;
        }
    }

    &__actions {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
    }

    &__primary-form {
        margin: $catalog-gird-item__primary-form-margin;
        @include mq($screen-m) {
            flex: 1;
        }
    }

    &__primary-action {
        display: flex;
        justify-content: center;
        width: 100%;
        padding: $catalog-grid-item__primary-padding;

        &:hover,
        &.focus-visible {
            .icon {
                fill: $catalog-grid-item__primary-icon-fill;
            }
        }
    }

    &__cart-icon {
        @include mq($screen-m) {
            display: none;
        }
    }

    &__cart-text {
        display: none;
        @include mq($screen-m) {
            display: block;
        }
    }

    &__secondary-action {
        display: flex;
        align-items: center;
        justify-content: center;
        .button {
            height: $catalog-grid-item__action-size;
            width: $catalog-grid-item__action-size;
            padding: $catalog-grid-item__action-padding;
            background: $catalog-grid-item__action-background;
            border: $catalog-grid-item__action-border;
            cursor: $catalog-grid-item__action-cursor;
            &:hover,
            &.focus-visible {
                .button__icon {
                    fill: $catalog-grid-item__action-icon-fill-hover;
                }
            }
            .button__icon {
                width: $catalog-grid-item__action-icon-size;
                height: $catalog-grid-item__action-icon-size;
                transition: $catalog-grid-item__action-icon-transition;
                fill: $catalog-grid-item__action-icon-fill;
            }
        }
    }
}
