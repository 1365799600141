$header__border-color                   : $gray-light !default;

$header__wrapper-padding                : 0 !default;
$header__wrapper-padding\@medium        : 12px 0 !default;

$header__logo-padding                   : 12px 0 12px $spacer !default;
$header__logo-padding\@medium           : 0 0 0 $spacer !default;

$header__buttons-width                  : calc(100% - 48px)  !default;
$header__buttons-width\@medium          : calc(4 * 48px + 3 * #{$spacer}) !default;
$header__buttons-width\@extra-large     : calc(4 * 48px + 3 * #{$spacer--medium}) !default;

$header__search-wrapper-padding         : 0 0 0 24px !default;
$header__search-wrapper-padding\@medium : 0 24px !default;

.header {
    position: relative;
    border-bottom: 1px solid $header__border-color;
    @include mq($screen-l) {
        border-bottom: none;
    }

    &__wrapper {
        position: relative;
        justify-content: space-between;
        display: flex;
        flex-flow: row wrap;
        padding: $header__wrapper-padding;
        @include mq($screen-m) {
            padding: $header__wrapper-padding\@medium;
        }

        @include mq($screen-l) {
            border-bottom: 1px solid $header__border-color;
        }
    }

    &__logo {
        order: 3;
        padding: $header__logo-padding;
        border-top: 1px solid $header__border-color;
        @include mq($screen-m) {
            padding: $header__logo-padding\@medium;
            border-top: none;
        }
    }

    &__search-wrapper {
        display: flex;
        flex-flow: column nowrap;
        flex: 1 50%;
        order: 5;
        justify-content: center;
        padding: $header__search-wrapper-padding;
        border-top: 1px solid $header__border-color;
        @include mq($screen-m) {
            order: 3;
            flex-direction: column;
            justify-content: center;
            padding: $header__search-wrapper-padding\@medium;
            border-top: none;
        }
    }

    &__minicart {
        position: relative;
    }

    &__minicart-content {
        top: calc(100% - 1px);
        &.popup {
            position: absolute;
            right: 0;
        }
    }

    &__buttons {
        display: flex;
        order: 2;
        flex: 1 $header__buttons-width;
        max-width: $header__buttons-width;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: flex-end;
        @include mq($screen-m) {
            order: 4;
            flex: 1 $header__buttons-width\@medium;
            max-width: $header__buttons-width\@medium;
        }

        @include mq($screen-xl) {
            flex: 1 $header__buttons-width\@extra-large;
            max-width: $header__buttons-width\@extra-large;
        }
    }
}
