@font-face {
    font-family: 'Montserrat';
    src:
    url('../fonts/montserrat-regular.woff2') format('woff2'),
    url('../fonts/montserrat-regular.woff') format('woff'),
    url('../fonts/montserrat-regular.svg') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Montserrat';
    src:
    url('../fonts/montserrat-bold.woff2') format('woff2'),
    url('../fonts/montserrat-bold.woff') format('woff'),
    url('../fonts/montserrat-bold.svg') format('svg');
    font-weight: 700;
    font-style: normal;
}
@font-face {
    font-family: 'Montserrat';
    src:
    url('../fonts/montserrat-black.woff2') format('woff2'),
    url('../fonts/montserrat-black.woff') format('woff'),
    url('../fonts/montserrat-black.svg') format('svg');
    font-weight: 900;
    font-style: normal;
}
