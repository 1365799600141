$logo__max-width               : 84px !default;
$logo__max-width\@medium       : 112px !default;
$logo__image-height            : 25px !default;
$logo__image-height\@medium    : 34px !default;

.logo {
    display: flex;
    align-items: center;
    flex: 1 $logo__max-width;
    justify-content: center;
    max-width: $logo__max-width;

    @include mq($screen-m) {
        flex: 1 $logo__max-width\@medium;
        max-width: $logo__max-width\@medium;
    }

    &__image {
        height: $logo__image-height;

        @include mq($screen-m) {
            height: $logo__image-height\@medium;
        }
    }
}
